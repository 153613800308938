<template>
  <div v-if="!alertBannerHidden">
    <div class="ui alert board alert-spacing large">
      <div class="content">
        <span class="ui text">For speedier and more accurate seaches connect your financial data</span>
        <Integrations @complete="hideAlertBanner" />
      </div>
      <span class="ui icon-close m-l-10" v-if="closeAlertEnabled">
        <button class="circular ui icon primary button btn btn-small" @click.prevent="closeAlert">
          <i class="icon material-icons">close</i>
        </button>
      </span>
      <div class="ui icon-close-button m-l-10" v-if="closeAlertEnabled">
        <button class="circular ui btn-primary button" @click.prevent="closeAlert">I'll set this up another time</button>
      </div>
    </div>
  </div>
</template>

<script>
import Integrations from '@/components/integrations'

export default {
  name: 'AlertIntegrations',
  props: {
    closeAlertEnabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Integrations
  },
  data() {
    return {
      alertBannerHidden: !this.$config.whitelabel.features.linkBankAccount && !this.$config.whitelabel.features.linkAccountancy
    }
  },
  methods: {
    hideAlertBanner() {
      this.alertBannerHidden = true
    },
    closeAlert() {
      this.$emit('close-alert')
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/styles/swoop/_variables.scss';
.ui.alert {
  border: 1px solid var(--color-secondary-50);
  border-radius: 40px;
  height: 62px;
  padding: 15px 16px;
  background-color: $color-white;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);

  @media only screen and (max-width: 768px) {
    height: 100%;
    padding: 12px;
  }

  .ui.text {
    font-size: 14px;
    line-height: 16px;
    margin-right: 16px;
  }
  .ui.btn-small {
    padding: 0;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.large {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .integration-links {
        display: flex;
        .ui.button {
          padding: 0;
          height: 36px;
          min-width: 165px;
          font-size: 12px;
          &:first-child {
            margin-right: 0.5rem;
          }
          &:last-child {
            min-width: 235px;
          }
        }
      }
    }
    .icon-close-button {
      display: none;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .ui.alert.large {
    .content .text {
      display: none;
    }
  }
}
@media only screen and (max-width: 770px) {
  [class*='mobile hidden'],
  [class*='tablet only']:not(.mobile),
  [class*='computer only']:not(.mobile),
  [class*='large screen only']:not(.mobile),
  [class*='widescreen only']:not(.mobile),
  [class*='or lower hidden'],
  .ui.alert.large {
    display: flex;
    flex-direction: column;
    button {
      margin: 1rem 0;
      width: 100%;
    }
    .content .text {
      display: none;
    }
    .icon-close {
      display: none;
    }
    .icon-close-button,
    .content {
      display: block;
      width: calc(100% - 7em);
    }
  }
  .ui.alert.small {
    a {
      text-decoration: none;
    }
    display: flex;
  }
}
@media only screen and (max-width: 500px) {
  .ui.alert.large {
    .icon-close-button,
    .content {
      display: block;
      width: 100%;
    }

    .icon-close-button {
      .button {
        background: var(--color-secondary-500);
      }
    }
  }
}
</style>
